import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/ru';

const Stats = ({ stats, dates }) => {
  let date;
  if(dates.month) {
    date = <Moment interval={0} locale="ru" parse="M D" format="D MMMM">{dates.month} {dates.date}</Moment>;
  } else {
    date = <Moment interval={0} locale="ru" format="DD MMMM"></Moment>
  }
  return (
    <div class="col-12">
      <h1>История погоды <br />в Санкт-Петербурге <br />
          {date}
      </h1>
      <p>Минимальная температура:<br /> <span class="temp">{stats.minTemp}°С</span></p>
      <p>Максимальная температура:<br /> <span class="temp">{stats.maxTemp}°С</span></p>
      <p>Средняя температура:<br /> <span class="temp">{stats.averageTemp}°С</span></p>
    </div>
  )
};

export default Stats