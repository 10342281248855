import React from 'react';

class DateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month: '',
      date: '',
      dDisabled: '',
      sDisabled: ''
    };

    this.handleMonth = this.handleMonth.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleMonth(event) {
    this.setState({month: event.target.value});
    this.setState({dDisabled: 'false'});
  }

  handleDate(event) {
    this.setState({date: event.target.value});
    this.setState({sDisabled: 'false'});
  }

  handleSubmit(event) {
    this.props.fetchApi(this.state.month, this.state.date);
    event.preventDefault();
  }

  render() {
    let dateArr = [...Array(31)];
    // dateArr.shift();
    const dates = dateArr.map((e, i) => 
      <option value={++i} key={i}>{i}</option>
    );
    return (
      <div class="col-12">
      <h2>Выбрать другой день</h2>
      <form action="/" method="GET" onSubmit={this.handleSubmit}>
        <div class="row">
          <div class="col-12">
            <div class="form-group"><label for="month"></label>
            <select class="form-control-sm" id="month" name="month" value={this.state.month} onChange={this.handleMonth}>
                <option value="" disabled selected>Месяц</option>
                <option value="1">Январь</option>
                <option value="2">Февраль</option>
                <option value="3">Март</option>
                <option value="4">Апрель</option>
                <option value="5">Май</option>
                <option value="6">Июнь</option>
                <option value="7">Июль</option>
                <option value="8">Август</option>
                <option value="9">Сентябрь</option>
                <option value="10">Октябрь</option>
                <option value="11">Ноябрь</option>
                <option value="12">Декабрь</option>
              </select></div>
          </div>
          <div class="col-12">
            <div class="form-group"><label for="day"></label>
            <select class="form-control-sm" id="day" name="date" value={this.state.date} onChange={this.handleDate} disabled={!this.state.dDisabled}>
                <option value="" disabled selected>День</option>
                { dates }
              </select></div>
          </div>
          <div class="col-12">
            <div class="form-group"><button class="btn btn-outline-light btn-sm" type="submit" disabled={!this.state.sDisabled}>Показать</button></div>
          </div>
        </div>
      </form>
      </div>
    );
  }
}

export default DateForm; 