import React, { Component } from 'react';
import Temps from './components/temps';
import Stats from './components/stats';
import Dates from './components/dates';
class App extends Component {
  state = {
    temps: [],
    stats: [],
    dates: []
  }

constructor(props) {
    super(props);
    this.fetchApi = this.fetchApi.bind(this);
  }

  fetchApi(month = '', date = '') {
    let url = 'http://ex0maiyf.beget.tech/api/',
        monthUrl = '',
        dateUrl = '';
    if (month !== '') {
      monthUrl = '?month=' + month;
    }
    if (date !== '') {
      dateUrl = '&date=' + date;
    }
    fetch(url + monthUrl + dateUrl)
    .then(res => res.json())
    .then((data) => {
      if (data.stats.count === 0) {
        alert('Нет результатов на данный период');
        return false;
      }
      this.setState({ 
        temps: data.temps,
        stats: data.stats,
        dates: { month, date }
      });
    })
    .catch(console.log);
  }

  componentDidMount() {
    this.fetchApi();
  }

  render() {
    return (
      <div>
        <Stats stats={this.state.stats} dates={this.state.dates} />
        <Dates fetchApi = {this.fetchApi} />
        <Temps temps={this.state.temps} />
      </div>
    )
  }
}

export default App