import React from 'react';

const Temps = ({ temps }) => {
  return (
    <div class="col-12">
      <h2>Температура по годам</h2>
      {temps.map((temp) => (
        <div class="row">
          <div class="col-6">
            <p> <span>{temp.year}</span></p>
          </div>
          <div class="col-6 text-right">
            <p> <span class="temp">{temp.temp}°С</span></p>
          </div>
        </div>
      ))}
    </div>
  )
};

export default Temps